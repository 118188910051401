import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles,
    ImmediateOutputParameters,
    GenericPosNotSupportedErrorNotes
} from "../../components/server-guide";
import {options} from "../../data/db2";
import {Callout} from "../../components/alert";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        SQLAPI++ library requires DB2 CLI version 6.x or higher.
    </Callout>
);
const sDBString = () => (
    <p>
        Any valid DB2 connection string
    </p>
);

const UserID = () => (<p>A string containing a user name to use when establishing the connection.</p>);

const Page = () => (
    <ServerGuide server="DB2"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 outputParamNotes={() => <ImmediateOutputParameters server="DB2"/>}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="DB2"
                                                                      nativeCommand="SQLCancel"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="DB2"
                                                                        cppHeader="db2API.h"
                                                                        apiClass="db2API"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="DB2"
                                                                                handleClass="db2ConnectionHandles"
                                                                                cppHeader="db2API.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="DB2"
                                                                          handleClass="db2CommandHandles"
                                                                          cppHeader="db2API.h"/>}
                 errorNotes={() => <GenericPosNotSupportedErrorNotes server="DB2" />}
    />
);

export default Page;
