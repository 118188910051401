import React from "react";
import {ApiLink} from "../components/link";
import {CodeBlend} from "../components/code";

const options = [
    {
        name: 'DB2CLI.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ Library to use specified DB2 CLI library.`,
        valid:
            <>
                Any valid DB2 CLI library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"db2cli.dll"</code></li>
                <li>Linux - {' '}<code>"libdb2.so"</code></li>
            </ul>,
        special:
            <>
                <code>"STATIC"</code>, forces using the linked DB2 client API functions when
                the library is compiled with {' '}<CodeBlend>SA_STATIC_DB2</CodeBlend>{' '} build option.
            </>
    },
    {
        name: 'PreFetchRows',
        scope: 'command',
        description: `Forces SQLAPI++ library to fetch rows in bulk, rather than retrieving records one by one.`,
        valid: 'String containing number of rows in the fetch buffer',
        defaultValue: <code>"1"</code>
    },
    {
        name: 'ReturnStatus',
        scope: 'command',
        description:
            <>
                DB2 stored procedures always return a value, and SQLAPI++ automatically creates
                {' '}<ApiLink>SAParam</ApiLink>{' '} object to represent the function return value.
                This option allows to cancel creating this {' '}<ApiLink>SAParam</ApiLink>{' '} object.
            </>,
        valid:
            <ul>
                <li>
                    <code>"Ignore"</code>{' '} - SQLAPI++ does not create {' '}<ApiLink>SAParam</ApiLink>{' '} object to
                    represent function return value
                </li>
                <li>
                    <code>"Default"</code>{' '} - SQLAPI++ creates {' '}<ApiLink>SAParam</ApiLink>{' '} object to
                    represent function return value
                </li>
            </ul>,
        defaultValue: <code>"Default"</code>
    },
    {
        name: 'UseDynamicCursor, Scrollable',
        scope: 'command',
        description:
            <>
                Forces SQLAPI++ to use scrollable dynamic command handle. Sets the command handle attributes
                {' '}<CodeBlend>SQL_ATTR_CURSOR_TYPE</CodeBlend>{' '} = {' '}<CodeBlend>SQL_CURSOR_DYNAMIC</CodeBlend>{' '}
                and {' '}<CodeBlend>SQL_ATTR_CONCURRENCY</CodeBlend> = {' '}<CodeBlend>SQL_CONCUR_LOCK</CodeBlend>.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    },
    {
        name: 'SetCursorName',
        scope: 'command',
        description: `Allows to define the cursor name SQLAPI++ uses for the server side cursor.`,
        valid:
            <>
                See {' '}<CodeBlend>SQLSetCursorName</CodeBlend>{' '} documentation.
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'ExecDirect',
        scope: 'command',
        description:
            <>
                Forces SQLAPI++ to use {' '}<CodeBlend>SQLExecDirect</CodeBlend>{' '} instead of
                {' '}<CodeBlend>SQLExecute</CodeBlend>{' '} API function.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'SQL_ATTR_CONCURRENCY',
        scope: 'command',
        description: `Sets the statement concurrency attribute. See DB2 CLI documentation for additional
                      information.`,
        valid:
            <>
                <code>"SQL_CONCUR_READONLY"</code>,
                {' '}<code>"SQL_CONCUR_VALUES"</code>,
                {' '}<code>"SQL_CONCUR_ROWVER"</code>,
                {' '}<code>"SQL_CONCUR_LOCK"</code>
            </>
    },
    {
        scope: "command",
        name: 'SQL_ATTR_CURSOR_TYPE',
        description: `Sets the statement cursor type. See DB2 CLI documentation for additional information.`,
        valid:
            <>
                <code>"SQL_CURSOR_FORWARD_ONLY"</code>,
                {' '}<code>"SQL_CURSOR_KEYSET_DRIVEN"</code>,
                {' '}<code>"SQL_CURSOR_DYNAMIC"</code>,
                {' '}<code>"SQL_CURSOR_STATIC"</code>
            </>
    },
    {
        scope: 'command',
        name: 'SQL_ATTR_CURSOR_SCROLLABLE',
        description: `Configures the statement cursor to be scrollable. See DB2 CLI documentation for an additional information.`,
        valid:
            <>
                <code>"SQL_NONSCROLLABLE"</code>,
                {' '}<code>"SQL_SCROLLABLE"</code>
            </>
    },
    {
        scope: 'command',
        name: 'SQL_ATTR_CURSOR_SENSITIVITY',
        description: `Sets the statement cursor sensitivity. See DB2 CLI documentation for additional information.`,
        valid:
            <>
                <code>"SQL_UNSPECIFIED"</code>,
                {' '}<code>"SQL_INSENSITIVE"</code>,
                {' '}<code>"SQL_SENSITIVE"</code>
            </>
    },
    {
        name: 'SQL_ATTR_QUERY_TIMEOUT',
        scope: 'command',
        description: `Sets the integer value corresponding to the number of seconds to wait for an SQL statement
                      to execute before returning to the application.`,
        valid:
            <>
                String that represents an integer value in seconds
            </>,
        defaultValue:
            <>none</>
    }
];

export {
    options
};
